import * as React from "react"
import Header from "../components/header-mentor"
import Footer from "../components/footer"
import imgProfile from "../images/blake-cv.png"
import logoGreen from "../images/logo-green.png"
import ContactForm from "../components/contact-form"
import GDPRConsent from "../components/gdpr-consent"
import Seo from "../components/seo"
import PackagekOffer from "../components/package-block/blake"
import DiscountBanners from "../components/discount-banners"

function coachingCurriculum() {
  return (
    <div className="text-left px-10 py-0">

      <b>Technical Analysis:</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Basic technical analysis – chart patterns and how to trade them.
          <ul className="inner-list">
            <li>Bullish patterns</li>
            <li>Bearish patterns</li>
          </ul>
        </li>
        <li>
          Candlestick analysis and how to trade them. Which patterns are best?
        </li>
        <li>
          Drawing trend lines – body vs wick debate
        </li>
        <li>
          RSI and identifying divergences (trade application)
        </li>
        <li>
          Trend Trading vs Countertrend trading. What is right for you?
        </li>
      </ul>

      <b>Fibonacci Analysis:</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          What (and why) Fibonacci Analysis is key to a traders success
        </li>
        <li>
          Fibonacci confluence/clustering and how to use it
        </li>
        <li>
          Understanding extensions
        </li>
        <li>
          Fibonacci fade trades and risk managing counter trend moves
        </li>
      </ul>

      <b>Trading Psychology:</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Dealing with drawdowns
          <ul className="inner-list ml-8">
            <li>How to avoid drawdowns</li>
            <li>How to recover from drawdowns</li>
          </ul>
        </li>
        <li>
          Dealing with streaks
          <ul className="inner-list ml-8">
            <li>Winning</li>
            <li>Losing</li>
          </ul>
        </li>
        <li>Realistic expectations</li>
        <li>Trade Planning</li>
        <li>Risk Management
          <ul className="inner-list ml-8">
            <li>Important of risk management</li>
            <li>Risk/Reward targets. Targets vs reality</li>
            <li>Importance of cutting losses quickly</li>
          </ul>
        </li>
      </ul>
      <b>Building a Trade Plan (Personalized):</b>
      <ul className="basic-list ml-14 mb-4">
        <li>Understanding your (student) trading capabilities and what could be a good trading plan based on you and what the market can/will be able to give you.</li>
        <li>Focus on spot FX Trading</li>
      </ul>

    </div>
  )
}

const IndexPage = () => {
  const mentor = {
    name: "Blake Morrow",
    firstName: "Blake",
    twitter: "@pipczar",
    headerText: "Are you interested in learning how to spot chart patterns, or to learn the art of Fibonacci analysis? This course is for you.",
    videoUrlCode: "JsOs_EoqmSU",
  }
  return (
    <>
      <Seo title='Trader Coach Blake' />
      <GDPRConsent />
      <Header props={mentor}/>
      <main>
        <>
          {<DiscountBanners mentor='blake' />}
          <div className="bg-gray-900 text-white pt-8" id="bio">
            <div className="lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="flex items-end">
                <div className="">
                  <img
                    className="inline w-40 md:w-60 lg:w-80"
                    src={imgProfile}
                    alt={'Blake'}
                  />
                </div>
                <div className="px-2 md:pb-6">
                  <span className="pb-3 block text-green-400 text-3xl font-bold">{mentor.name}</span>
                  <span className="block text-white md:text-3xl font-bold">Co-Founder</span>
                  <span className="block text-green-400 md:text-xl">ForexAnalytix.com</span>
                </div>
              </div>

              <div className="text-justify px-6 pt-6">
                Blake’s skillsets range from technical analysis, mixed in with developing a macro theme for the markets to help drive his bias in the markets.
                <br /><br />
                As a trader, Blake has managed his own capital, but larger amounts for private funds and individuals over the years. He is accustomed to adjusting size and risk based on account sizes and overall goals which have been set based on what was expected of him. Therefore, he has a lot of experience in dealing with a very wide range of “targets” of what he expects to achieve for himself and for when he is trading for others.
                <br /><br />
                His risk management skills are what has afforded him the opportunities to trade for different funds and high net worth individuals in years past, and being able to recover after drawdowns and managing expectations when on a “streak” is invaluable knowledge that he likes to pass on to his students.
                <br /><br />
                <span className="font-bold">
                  If you want to be coached by Blake and are interested in learning how to spot chart patterns, or to learn the art of Fibonacci analysis he recommends at least two or three coaching sessions. For more “risk management” and “trading psychology” you may want to book at least two sessions (and possibly more) based on your individual case.
                </span>

              </div>

              <div className="text-center">
                <img
                  className="inline h-60 w-60 lg:w-80 lg:h-80"
                  src={logoGreen}
                  alt="logo"
                />

              </div>
            </div>


          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-50 text-gray-900 py-8">
            <div className="block text-center text-xl font-bold px-2 md:text-xl">
              <div className="text-green-400 text-2xl">BOOK NOW</div>
              Buy 3 sessions of 1 hour and get $150 discount<br />
              <span className="text-green-400">OR</span><br />
              Buy 1 session of 1 hour and get 40% discount<br />
              <span className="text-xl md:text-lg font-normal">* applies one time only for your first 1 hour session</span>
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-900 text-white">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-8 px-4 sm:px-6 sm:pt-16 lg:pb-8 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold py-8 text-green-400">
                Coaching Curriculum
              </div>
              {coachingCurriculum()}
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <PackagekOffer />

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-12 px-4 sm:px-6 sm:pt-16 lg:pb-6 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold mb-6 mt-6 text-white text-center">
                Get 40% off for your first 1 hour session using the coupon: <span className="text-red-600 text-4xl">40%OFF</span>
              </div>
              <div className="text-xl font-bold mt-6 text-center">
                <u>Billed at $150</u>.
              </div>
              <div className="font-normal text-lg">
                Standard price for 1 hour session $250.
              </div>

              <div className="mt-4 flex justify-center" id="booknow">
                <div className="inline-flex">
                  <a
                    href="https://TraderCoach.as.me/?appointmentType=56188398"
                    target="_blank" rel="noopener" rel="noreferrer"
                    // className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover"
                    className="rounded-full w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold text-white bg-gray-900 hover:text-green-400 md:py-4 md:text-lg md:px-10"
                  >
                    Buy 1 Session Now!
                  </a>
                </div>
              </div>

              <div className="font-normal text-lg mt-8">
                * Click on the "Redeem Coupon or Package" button to enter the coupon code.
              </div>
              <div className="mb-4"></div>
            </div>
          </div>

          <div className="relative max-w-7xl mx-auto pt-10 pb-12 px-0 lg:px-8 h-full" id="booknow">
            <section className="bg-gray-100 overflow-hidden" id="blake">
              <iframe
                src="https://app.acuityscheduling.com/schedule.php?owner=30981395&appointmentType=56188398" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe>
                <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
            </section>
          </div>

          <ContactForm props={{ name: mentor.firstName, twitter: mentor.twitter}}/>

        </>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
